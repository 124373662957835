import React from 'react';
import { AppProvider } from './src/context';
import GTM from '@src/utils/GTM.js';

export const onRouteUpdate = () => {
    GTM.reset();
    GTM.handleRoute();
};

export const wrapRootElement = ({ element }) => <AppProvider>{element}</AppProvider>;

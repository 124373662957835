import React, { useRef, useEffect } from 'react';

const CookieBannerContainer = () => {
    const cbContainer = useRef(null);

    useEffect(() => {
        cbContainer.current = document.getElementById('cookie-banner-container');

        let counter = 0;
        const moveOneTrustBanner = () => {
            const oneTrustBanner = document.getElementById('onetrust-consent-sdk');

            if (oneTrustBanner && cbContainer.current) {
                cbContainer.current.appendChild(oneTrustBanner);
                window.clearInterval(intervalId);
            } else {
                counter += 1;
                if (counter >= 20) {
                    window.clearInterval(intervalId);
                }
            }
        };

        const intervalId = setInterval(moveOneTrustBanner, 500);

        return () => {
            window.clearInterval(intervalId);
        };
    }, []);

    return <div id="cookie-banner-container" ref={cbContainer}></div>;
};

export default CookieBannerContainer;
